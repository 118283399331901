import * as React from "react";
import { useState } from "react";
import { Slider } from "react-semantic-ui-range";
import { graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";

import Layout from "../components/layout";
import SecondaryClouds from "../components/secondary-clouds";
import Link from "../components/link";

const EarningsCalculator = ({ data }) => {
  const valueFollowersStart = 320000;
  const valuePriceStart = 31;
  const [valueFollowers, setValueFollowers] = useState(valueFollowersStart);
  const [valuePrice, setValuePrice] = useState(31);
  const settings = {
    start: valueFollowersStart,
    min: data.followers.followersMinimum,
    max: data.followers.followersMaximum,
    step: 1000,
    onChange: (valueFollowers) => {
      setValueFollowers(valueFollowers);
    },
  };
  const settingsPrice = {
    start: valuePriceStart,
    min: data.price.priceMinimum,
    max: data.price.priceMaximum,
    step: 1,
    onChange: (valuePrice) => {
      setValuePrice(valuePrice);
    },
  };
  let lowPrice = valueFollowers * valuePrice * 0.01;
  lowPrice = lowPrice.toLocaleString("en-US");
  let highPrice = valueFollowers * valuePrice * 0.05;
  highPrice = highPrice.toLocaleString("en-US");

  return (
    <Layout>
      <HelmetDatoCms seo={data.page.seoMetaTags} />
      <main className="calculator-page">
        <section className="calculator-page-container">
          <h1 className="title prewrap">{data.page.title}</h1>
          <p className="body lede">{data.page.body}</p>
          <div className="calculator-page-followers">
            <h5>{data.followers.followersTitle}</h5>
            <div class="slide-container js-slide-container">
              <label className="slide-label js-slide-label" color="red">
                <h6>{valueFollowers.toLocaleString("en-US")}</h6>
              </label>
              <Slider
                valueFollowers
                color="red"
                settings={settings}
                style={{
                  trackFill: {
                    background:
                      "linear-gradient(90deg, #FF974A 0%, #FF6B00 100%)",
                    height: "2px",
                    top: "15px",
                  },
                  track: {
                    height: "1px",
                    backgroundColor: "black",
                    top: "16px",
                  },
                  thumb: {
                    backgroundColor: "#FF974A",
                    width: "32px",
                    height: "32px",
                    boxShadow:
                      "#FF6B00 0px 0px 0px 0px, #FF6B00 0px 0px 0px 0px inset;",
                  },
                }}
              />
              <span className="slider-label minimum">
                <h5>1K</h5>
              </span>
              <span className="slider-label maximum">
                <h5>1M</h5>
              </span>
            </div>
          </div>
          <div className="calculator-page-price">
            <h5>{data.price.priceTitle}</h5>
            <div class="slide-container js-slide-container">
              <label className="slide-label js-slide-label" color="red">
                <h6>${valuePrice}</h6>
              </label>
              <Slider
                valueFollowers
                color="red"
                settings={settingsPrice}
                style={{
                  trackFill: {
                    background:
                      "linear-gradient(90deg, #FF974A 0%, #FF6B00 100%)",
                    height: "2px",
                    top: "15px",
                  },
                  track: {
                    height: "1px",
                    backgroundColor: "black",
                    top: "16px",
                  },
                  thumb: {
                    backgroundColor: "#FF974A",
                    width: "32px",
                    height: "32px",
                    boxShadow:
                      "#FF6B00 0px 0px 0px 0px, #FF6B00 0px 0px 0px 0px inset;",
                  },
                }}
              />
              <span className="slider-label minimum">
                <h5>${data.price.priceMinimum}</h5>
              </span>
              <span className="slider-label maximum">
                <h5>${data.price.priceMaximum}</h5>
              </span>
            </div>
          </div>
          <div className="calculator-page-outcome">
            <div className="calculator-page-callout">
              <h5 className="bold">
                {data.earnings.earningsTextOne} <br />${lowPrice}{" "}
                {data.earnings.earningsTextTwo} ${highPrice}{" "}
                {data.earnings.earningsTextThree}
              </h5>
              <Link
                href="https://app.snipfeed.co/signup"
                className="btn btn--purple btn--arrow"
              >
                <span className="btn-text">{data.earnings.btnText}</span>
              </Link>
            </div>
            <p className="small-print small">{data.earnings.smallPrint}</p>
          </div>
        </section>
        <SecondaryClouds />
      </main>
    </Layout>
  );
};

export default EarningsCalculator;

export const query = graphql`
  query EarningsCalculator($language: String!) {
    pricing: datoCmsPricing(locale: { eq: $language }) {
      billedAnnually
      billedAnnuallyOffer
      billedMonthly
      billedMonthlyOffer
      body
      headline
      mostPopularCopy
      perMonthCopy
      prices {
        originalId
        annualPrice
        body
        btnText
        btnUrl
        featuresIntro
        headline
        monthlyPrice
        mostPopular
        perMonth
        features {
          originalId
          priceFeature
          bold
        }
      }
    }
    page: datoCmsEarningsCalculator(locale: { eq: $language }) {
      title
      body
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    followers: datoCmsEarningsCalculator(locale: { eq: $language }) {
      followersTitle
      followersMinimum
      followersMaximum
    }
    price: datoCmsEarningsCalculator(locale: { eq: $language }) {
      priceTitle
      priceMinimum
      priceMaximum
    }
    earnings: datoCmsEarningsCalculator(locale: { eq: $language }) {
      earningsTextOne
      earningsTextTwo
      earningsTextThree
      btnText
      smallPrint
    }
  }
`;
